import React from "react";
import * as PropTypes from "prop-types";

import SignUpForm from "../../forms/instances/SignUpForm";
import BlockSection from "../BlockSection";
import Title from "../../components/typography/Title";
import Block from "../Block";
import useLanguage from "../../hooks/useLanguage";

const SubmitApplicationBlock = ({ title, textSubmit, textDescription = '', additionalStyle }) => {
    const [currentLanguage] = useLanguage();
    return (
        <Block
            className={`Block_theme_form ${additionalStyle}`}
            config={{
                baseBorder: true,
                animation: '3'
            }}>
            <div className="container">
                <BlockSection>
                    {currentLanguage === 'ru' && <Title headerStyleNumb="2" level="h2" className="Block-Title-FOS">{title ? title : 'Оставить заявку'}</Title>}
                    {textDescription && <div className="Block-Description-FOS">{textDescription}</div>}
                    <div className="Block-Form">
                        <SignUpForm textSubmit={textSubmit} />
                    </div>
                </BlockSection>
            </div>
        </Block>

    );
};

SubmitApplicationBlock.propTypes = {
    title: PropTypes.string,
    textSubmit: PropTypes.string,
    additionalStyle: PropTypes.string
};

export default SubmitApplicationBlock;
